<template>
  <b-card v-if="modeerror == 0" >
    <!-- ref="refreshCard"
    action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button @click.prevent="add" variant="primary"> <feather-icon icon="PlusIcon" class="mr-50" />Tambah </b-button>
        <b-modal v-model="showModalTambah" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal" centered title="Form Tambah">
          <b-form>
            <b-form-group>
              <label for="nama_bank">Nama Bank</label>
              <b-form-input v-model="form.nama" id="nama_bank" type="text" placeholder="Nama Bank" />
            </b-form-group>
            <b-form-group>
              <label for="no_rekening">No. Rekening</label>
              <b-form-input v-model="form.no_rekening" id="no_rekening" placeholder="No. Rekening" />
            </b-form-group>
            <b-form-group>
              <label for="type">Tipe Rekening</label>
              <b-form-radio-group id="radio-group-1" v-model="form.type" name="radio-sub-component1">
                <b-form-radio value="income" v-b-popover.hover="'Tipe rekening ini untuk ditampilkan dipembayaran ketika checkout'"
                title="Petunjuk">Income 
                  <feather-icon icon="HelpCircleIcon" />
                </b-form-radio>
                <b-form-radio value="withdraw" v-b-popover.hover="'Tipe rekening ini untuk ditampilkan ketika withdraw'"
                title="Petunjuk">Withdraw 
                  <feather-icon icon="HelpCircleIcon" />
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group>
              <label for="reward">Deskripsi</label>
              <b-form-input v-model="form.deskripsi" id="deskripsi" placeholder="Deskripsi" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submit"> Tambah </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary" ok-title="Ubah" cancel-title="Batal" centered title="Form Ubah">
          <b-form>
            <b-form-group>
              <label for="nama_bank">Nama Bank:</label>
              <b-form-input v-model="form.nama" id="nama_bank" type="text" placeholder="Nama Bank" />
            </b-form-group>
            <b-form-group>
              <label for="no_rekening">No. Rekening</label>
              <b-form-input v-model="form.no_rekening" id="no_rekening" placeholder="No. Rekening" />
            </b-form-group>
            <b-form-group>
              <label for="type">Tipe Rekening</label>
              <b-form-radio-group id="radio-group-1" v-model="form.type" name="radio-sub-component1">
                <b-form-radio value="income" v-b-popover.hover="'Tipe rekening ini untuk ditampilkan dipembayaran ketika checkout'"
                title="Petunjuk">Income 
                  <feather-icon icon="HelpCircleIcon" />
                </b-form-radio>
                <b-form-radio value="withdraw" v-b-popover.hover="'Tipe rekening ini untuk ditampilkan ketika withdraw'"
                title="Petunjuk">Withdraw 
                  <feather-icon icon="HelpCircleIcon" />
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group>
              <label for="reward">Deskripsi</label>
              <b-form-input v-model="form.deskripsi" id="deskripsi" placeholder="Deskripsi" />
            </b-form-group>
            <b-form-group>
              <label for="reward">Rekening Utama ? </label>
              <b-form-checkbox class="ml-2" v-model="form.rekening_utama" switch inline> </b-form-checkbox>
              <!-- <b-form-input v-model="form.nilai" id="reward" placeholder="Poin" /> -->
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit"> Ubah </b-button>
              <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0" :label="$t('Per page')" label-size="sm" label-for="perPageSelect" >
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
        </b-form-group>
      </b-col>
      <!-- <b-col md="4" sm="8" class="my-1">
        <b-form-group :label="$t('Sort')" label-size="sm" label-for="sortBySelect" class="mb-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col> -->
      <b-col md="6" class="my-1">
        <b-form-group :label="$t('Filter')"label-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Tekan enter untuk cari"
                @keydown.enter.prevent="getData()"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="
                    filter = '';
                    getData();
                  "
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
        >
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>
          <template #cell(type)="row">
            <strong class="text-center">
              <b-badge variant="success" v-if="row.value == 'income'">Income</b-badge>
              <b-badge variant="danger" v-else>Withdraw</b-badge>
            </strong>
          </template>
          <template #cell(rekening_utama)="row">
            <strong class="text-center">
              <b-badge variant="success" v-if="row.value == true">Ya</b-badge>
              <b-badge variant="danger" v-else>Tidak</b-badge>
            </strong>
          </template>
          <template #cell(actions)="row">
            <b-button v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1" variant="outline-info">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)" class="mr-1" variant="outline-danger">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
      </b-col>
    </b-row>
  </b-card>
  <b-card v-else class="text-center">
    <!-- <autentikasi-akun></autentikasi-akun> -->
    <sedang-perbaikan></sedang-perbaikan>
  </b-card>
</template>

<script>
import SedangPerbaikan from "../SedangPerbaikan.vue"
import AutentikasiAkun from "../Autentikasi.vue"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VBPopover, BPopover , BCard,BFormRadio, BFormRadioGroup, BTable, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    SedangPerbaikan,
    AutentikasiAkun,
    VBPopover, BPopover , BCard,BFormRadio, BFormRadioGroup, BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    'b-popover': VBPopover,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      activeAction: null,
      label: 'tambah',
      showModalTambah: false,
      showModalEdit: false,
      id: null,
      form: {
        nama: "",
        no_rekening: "",
        type: null,
        deskripsi: "",
        rekening_utama: null,
      },
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "nama", label: "Nama Bank", sortable: true },
        { key: "no_rekening", label: "No. Rekening", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        { key: "deskripsi", label: "Deskripsi", sortable: true },
        { key: "rekening_utama", label: "Rekening Utama", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      modeerror: 0,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items

    this.getData();
  },
  methods: {
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalTambah = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = new FormData();
          payload.append("id", item.id);
          payload.append("fungsi", 1);
          this.$store
            .dispatch("rekening/save", payload)
            .then(() => {
              this.pesanBerhasilHapus();
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        nama: "",
        no_rekening: "",
        type: "",
        deskripsi: "",
        rekening_utama: null,
      };
      this.showModalTambah = false;
      this.showModalEdit = false;
    },
    submit() {
      if (this.form.nama == null || this.form.nama == "") {
        this.pesanGagalSimpan();
        return false;
      }
      let payload = new FormData();
      if (this.isCabang || this.isMarketing) {
        payload.append("member_id", this.user.member_id);
      }
      payload.append("nama", this.form.nama);
      payload.append("no_rekening", this.form.no_rekening);
      payload.append("type", this.form.type);
      payload.append("deskripsi", this.form.deskripsi);
      if (this.id) {
        payload.append("id", this.id);
        payload.append("rekening_utama", this.form.rekening_utama == true ? 1 : 0);
      }

      this.$store
        .dispatch("rekening/save", payload)
        .then((response) => {
          if (this.activeAction == "tambah") {
            this.id = null;
          const input = {
            tanggal: new Date(),
            nama: this.form.nama,
            karyawan_id : this.user.karyawan ? this.user.karyawan.id : this.user.member_id ,
            keterangan: this.form.deskripsi,
            rekening_id: response.id,
          }
          this.$store.dispatch("kas/save", [input])
                .then(() => {
                  this.getData();
                })
                .catch((e) => {
                  this.label = "Submit";
                  this.displayError(e);
                  return false;
                });
          }


          this.form = {
            nama: "",
            no_rekening: "",
            type: "",
            deskripsi: "",
            rekening_utama: null,
          };
          this.showModalTambah = false;
          this.showModalEdit = false;
          this.getData();
          this.pesanBerhasilSimpan();
        })
        .catch((e) => console.error(e));
    },
    edit(item) {
      this.activeAction ='edit';
      this.id = item.id;
      console.log(item);
      this.form = {
        nama: item.nama,
        no_rekening: item.no_rekening,
        type: item.type,
        deskripsi: item.deskripsi,
        rekening_utama: item.rekening_utama == 1 ? true : false,
      };
      this.showModalEdit = true;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      if (this.isCabang || this.isMarketing) {
        var params = {
          member_id: this.user.member_id,
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        };
      } else {
        var params = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
      };
      }
      this.$store
        .dispatch("rekening/getData", params)
        .then(() => {
      let items = JSON.parse(
        JSON.stringify(this.$store.state.rekening.datas)
      );
      let items_total = this.$store.state.rekening.totals;
          this.items = items;
          this.totalRows = items_total;
        })
        .catch((e) => 
        {
          this.displayError(e)
          console.error(e);
          this.modeerror = 1;
        });
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
